<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{ $t('myNews') }}</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button variant="info" @click="create">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr />
    <b-table
      striped
      bordered
      hover
      :items="notices"
      :fields="columns"
      :filter="filter"
    >
      <div slot="title" slot-scope="notice">
        <router-link
          :to="{
            name: 'FrontUserNotice',
            params: {
              lang: lang,
              console: user.console.toLowerCase(),
              playerSLug: user.slug,
              noticeSlug: notice.item.slug,
            },
          }"
          >{{ notice.item.title }}</router-link
        >
      </div>
      <div slot="image" slot-scope="notice" class="text-center">
        <i v-if="notice.item.image" class="fa fa-check"></i>
        <i v-else class="fa fa-times text-danger"></i>
      </div>
      <div slot="show" slot-scope="notice" class="text-center">
        <i v-if="notice.item.show" class="fa fa-check"></i>
        <i v-else class="fa fa-times text-danger"></i>
      </div>
      <template slot="actions" slot-scope="notice">
        <div class="w-100 text-center">
          <b-button variant="info" class="mr-2" @click="edit(notice.item)"
            ><i class="fa fa-pencil"></i
          ></b-button>
          <b-button variant="danger" @click="destroy(notice.item)"
            ><i class="fa fa-trash"></i
          ></b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      filter: '',
      columns: [
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'image', label: this.$t('image'), sortable: true },
        { key: 'show', label: this.$t('published'), sortable: true },
        { key: 'created_at', label: this.$t('createdAgo'), sortable: true },
        { key: 'actions', label: '' },
      ],
      console: this.$route.params.console,
    };
  },
  computed: {
    ...mapGetters(['loading', 'lang', 'notices', 'user']),
  },
  mounted() {
    this.changeTitleBar(this.$t('myNews'));
    if (this.notices.length < 1) {
      this.fetchData();
    }
  },
  methods: {
    create() {
      this.$store.dispatch('SET_NOTICE', {});
      this.$router.push({ name: 'NoticeUserAdd', params: { lang: this.lang } });
    },
    edit(notice) {
      this.$store.dispatch('SET_NOTICE', notice);
      this.$router.push({
        name: 'NoticeUserEdit',
        params: { lang: this.lang, noticeId: notice.id },
      });
    },
    destroy(notice) {
      this.$store.dispatch('SET_NOTICE', notice);
      this.$router.push({
        name: 'NoticeUserDelete',
        params: { lang: this.lang, noticeId: notice.id },
      });
    },
    fetchData() {
      const path = `auth/${this.lang}/${this.console}/notices/user/all`;
      this.$axios.get(path).then((response) => {
        this.$store.dispatch('SET_NOTICES', response.data.data);
      });
    },
  },
};
</script>
