var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('div',{staticClass:"title-actions"},[_c('h5',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('myNews')))]),_c('div',{staticClass:"btns"},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('search'),"autofocus":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-button',{attrs:{"variant":"info"},on:{"click":_vm.create}},[_c('i',{staticClass:"fa fa-plus"})]),_c('b-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"fa fa-arrow-left"})])],1)]),_c('hr'),_c('b-table',{attrs:{"striped":"","bordered":"","hover":"","items":_vm.notices,"fields":_vm.columns,"filter":_vm.filter},scopedSlots:_vm._u([{key:"title",fn:function(notice){return _c('div',{},[_c('router-link',{attrs:{"to":{
          name: 'FrontUserNotice',
          params: {
            lang: _vm.lang,
            console: _vm.user.console.toLowerCase(),
            playerSLug: _vm.user.slug,
            noticeSlug: notice.item.slug,
          },
        }}},[_vm._v(_vm._s(notice.item.title))])],1)}},{key:"image",fn:function(notice){return _c('div',{staticClass:"text-center"},[(notice.item.image)?_c('i',{staticClass:"fa fa-check"}):_c('i',{staticClass:"fa fa-times text-danger"})])}},{key:"show",fn:function(notice){return _c('div',{staticClass:"text-center"},[(notice.item.show)?_c('i',{staticClass:"fa fa-check"}):_c('i',{staticClass:"fa fa-times text-danger"})])}},{key:"actions",fn:function(notice){return [_c('div',{staticClass:"w-100 text-center"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"info"},on:{"click":function($event){return _vm.edit(notice.item)}}},[_c('i',{staticClass:"fa fa-pencil"})]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.destroy(notice.item)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }